import React from 'react'

import { Space, Typography } from 'antd'

import './landing-community-ep-text.scss'

export default function LandingCommunityEPText() {
  return (
    <Space
      direction="vertical"
      align="center"
      size={24}
      className="landing-community-ep-text"
    >
      <Typography.Title level={2} className="title font-archivo-expanded">
        Community Energy{' '}
        <Typography.Text
          type="success"
          className="title-color font-archivo-expanded"
        >
          Point
        </Typography.Text>
      </Typography.Title>
      <Typography.Text className="desc">
        Accumulate Energy Points (EP) via missions and activities to unlock
        limitless benefits and enhance your gaming experience!
      </Typography.Text>
    </Space>
  )
}
