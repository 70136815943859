import React, { Fragment } from 'react'

import { Card, Space, Typography } from 'antd'

const missionsSystemData = [
  'Social media missions',
  'Account registration missions',
  'Playtest feedback missions',
  'In-game missions',
]

export default function LandingMissionsSystemList() {
  return (
    <Fragment>
      {missionsSystemData.map((e, index) => (
        <Card className="item" bodyStyle={{ padding: 0 }} key={e}>
          <Space size={20}>
            <Typography.Text className="no font-archivo-expanded">{`0${
              index + 1
            }.`}</Typography.Text>
            <Typography.Text className="content font-archivo-expanded">
              {e}
            </Typography.Text>
          </Space>
        </Card>
      ))}
    </Fragment>
  )
}
