import { useRouter } from 'next/router'

import { Button, Col, Image, Row, Space, Typography } from 'antd'

import { ROUTES } from '@/constants/routes'
import { ABOUT_SPACE_3_URL } from '@/constants'

import MeshBG from '@/assets/images/landing-page/mesh-background.png'

import './index.scss'

const LandingHeroSection = () => {
  const router = useRouter()
  const onLaunchingApp = () => router.push(ROUTES.MISSION.INDEX)
  const onOpenDocs = () => window.open(ABOUT_SPACE_3_URL, '_blank')

  return (
    <Row>
      <Col className="landing-hero-section" span={24}>
        <Row className="inner-container">
          {/* LEFT WRAPPER */}
          <Col xs={24} lg={14} className="hero-banner-heading">
            <Space
              direction="vertical"
              className="hero-banner-heading--wrapper"
            >
              <Typography.Title className="title font-archivo-expanded">
                Your Daily{' '}
                <Typography.Text
                  type="success"
                  className="title font-archivo-expanded"
                  style={{ display: 'block' }}
                >
                  Gaming Gateway
                </Typography.Text>
              </Typography.Title>

              <Space className="btn-wrapper" size={20}>
                <Button type="primary" className="btn" onClick={onLaunchingApp}>
                  Launch App
                </Button>
                <Button className="btn" onClick={onOpenDocs}>
                  Docs
                </Button>
              </Space>
            </Space>
          </Col>

          {/* RIGHT WRAPPER */}
          <Col xs={24} lg={10} className="card-wrapper">
            <Row className="inner-card-wrapper" justify="center">
              <Col span={24} className="img-wrapper">
                <Image
                  src="/images/landing-hero-banner-card.png"
                  alt="Landing page hero banner card"
                  className="img"
                  preview={false}
                />

                <Image
                  src={MeshBG.src}
                  alt="mesh texture"
                  className="mesh"
                  preview={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LandingHeroSection
