import React from 'react'

import { Col, Row, Typography } from 'antd'

export default function LandingHowItWorksTitle() {
  return (
    <Row
      justify="center"
      gutter={[{ xs: 0 }, { xs: 16, sm: 24 }]}
      className="title-wrapper"
    >
      <Col span={24}>
        <Typography.Title level={2} className="title font-archivo-expanded">
          How It{' '}
          <Typography.Text
            type="success"
            className="title-work font-archivo-expanded"
          >
            Works
          </Typography.Text>
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Paragraph className="desc">
          Begin your journey on Space3 in just 4 easy steps
        </Typography.Paragraph>
      </Col>
    </Row>
  )
}
