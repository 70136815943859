import * as React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Button, Col, Image, Row, Space, Typography } from 'antd'
import { Navigation, Pagination } from 'swiper'

import { ArrowRightWithCircleIcon } from '@/assets/images/icon/ArrowRightWithCircle'

import { convertTwoDigits } from '@/helpers'
import { DEFAULT_NEXT_CLS, DEFAULT_PREV_CLS } from '@/constants/swiper'
import MeshBG from '@/assets/images/landing-page/mesh-background.png'

import './landing-rewards-center-card.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

type LandingRewardsCenterCardProps = {
  onSetCurrentPage: (index: number) => void
  currentPage: number
  totalPage: number
  cardUrl: string
}

const LandingRewardsCenterCard = ({
  onSetCurrentPage,
  currentPage,
  totalPage,
}: LandingRewardsCenterCardProps) => {
  const prefixCls = 'space3'
  const { paginationPosition = 'outer' } = {}

  return (
    <Row className="landing-hero-banner-card" justify="center" gutter={[0, 40]}>
      <Col span={24} className="card-wrapper">
        <div
          className={`sp3-swiper-container swiper-pagination-${paginationPosition}`}
        >
          <Swiper
            rewind={true}
            centeredSlides={true}
            navigation={{
              prevEl: `.${prefixCls}-${DEFAULT_PREV_CLS}`,
              nextEl: `.${prefixCls}-${DEFAULT_NEXT_CLS}`,
            }}
            slidesPerView={1}
            pagination={{
              clickable: false,
              enabled: false,
              type: 'bullets',
            }}
            modules={[Navigation, Pagination]}
            className="swiper-default-inner"
            style={{ height: '100%' }}
            onSlideChange={(swiper) => {
              onSetCurrentPage(swiper.activeIndex)
            }}
          >
            {[1, 2, 3, 4, 5].map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="swiper-slide-container"
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Image
                    src={`/images/reward-center-card-${item}.png`}
                    alt="ladning rewards center card"
                    style={{ inset: 0 }}
                    className="card"
                    preview={false}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Image
          src={MeshBG.src}
          alt="mesh texture"
          className="mesh"
          preview={false}
        />
      </Col>

      <Col span={24} className="pagination-wrapper">
        <Space size={15} align="center">
          <Button
            icon={<ArrowRightWithCircleIcon />}
            className={`icon ${prefixCls}-${DEFAULT_PREV_CLS} btn-navigate-swiper--md`}
            style={{ rotate: '180deg' }}
          />
          <Space className="text-page-wrapper" align="end">
            <Typography.Text className="current-page">
              {convertTwoDigits(currentPage)}
            </Typography.Text>
            <Typography.Text className="total-page">
              / {convertTwoDigits(totalPage)}
            </Typography.Text>
          </Space>
          <Button
            icon={<ArrowRightWithCircleIcon />}
            className={`icon ${prefixCls}-${DEFAULT_NEXT_CLS} btn-navigate-swiper--md`}
          />
        </Space>
      </Col>
    </Row>
  )
}

export default LandingRewardsCenterCard
