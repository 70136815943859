import React from 'react'

type props = {
  isTop?: boolean
}
export default function RewardsCenterLine({ isTop = false }: props) {
  return (
    <svg
      width="3150"
      height="25"
      viewBox="0 0 3150 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMin slice"
    >
      <path
        strokeWidth={1}
        d={
          isTop
            ? 'M0 0,1400 0,1425 25,1725 25,1750 0, 3150 0'
            : 'M0 25,1400 25,1425 0,1725 0,1750 25, 3150 25'
        }
        fill="#060708"
        stroke="#535353"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  )
}
