import React from 'react'
import { useRouter } from 'next/router'

import { Button, Col, Image, Row, Space, Typography } from 'antd'
import LandingInnerContainer from '@/components/containers/LandingInnerContainer'

import { ROUTES } from '@/constants/routes'

import LandingCommunityLogo from '@/assets/images/landing-community/landing-community-logo.png'

import './index.scss'

export default function LandingCommunities() {
  const router = useRouter()
  const onExploreMore = () => router.push(ROUTES.COMMUNITY.INDEX)

  return (
    <Row className="landing-communities">
      <Col span={24}>
        <LandingInnerContainer>
          <Row justify="center" gutter={[0, 48]}>
            <Col span={20}>
              <Typography.Title
                level={2}
                className="title font-archivo-expanded"
              >
                Games on{' '}
                <Typography.Text
                  className="title-space3 font-archivo-expanded"
                  type="success"
                >
                  Space3
                </Typography.Text>
              </Typography.Title>
            </Col>

            <Col span={24} className="img-wrapper">
              <Image
                src={LandingCommunityLogo.src}
                alt="games on space3"
                className="img"
                onClick={onExploreMore}
                preview={false}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={24}>
              <Space
                direction="vertical"
                align="center"
                className="btn-wrapper"
              >
                <Button className="btn" onClick={onExploreMore}>
                  Explore more
                </Button>
              </Space>
            </Col>
          </Row>
        </LandingInnerContainer>
      </Col>
    </Row>
  )
}
