import React from 'react'

import { Col, Row } from 'antd'
import LandingInnerContainer from '@/components/containers/LandingInnerContainer'
import LandingCommunityEPText from './LandingCommunityEPText'
import LandingCommunityEPListItem from './LandingCommunityEPListItem'

import './index.scss'

export default function LandingCommunityEP() {
  return (
    <Row className="landing-community-ep-container" justify="center">
      <Col span={24}>
        <LandingInnerContainer>
          <Row justify="center" className="content-wrapper">
            <Col xs={22} md={14} className="col">
              <Row gutter={[0, 80]}>
                <Col span={24}>
                  <LandingCommunityEPText />
                </Col>

                <Col span={24}>
                  <LandingCommunityEPListItem />
                </Col>
              </Row>
            </Col>
          </Row>
        </LandingInnerContainer>
      </Col>
    </Row>
  )
}
