import React from 'react'

import { Avatar, Card, Col, Image, Row, Space, Typography } from 'antd'

import GameControllerIcon from '@/assets/images/landing-page/how-it-work-1.png'
import WalletIcon from '@/assets/images/landing-page/how-it-work-2.png'
import PointIcon from '@/assets/images/landing-page/how-it-work-3.png'
import TreasureIcon from '@/assets/images/landing-page/how-it-work-4.png'
import Border from '@/assets/images/landing-page/how-it-work-border.svg'

const howItWorkData = [
  {
    desc: 'Log in via wallet or social media accounts',
    icon: WalletIcon,
  },
  {
    desc: 'Conquer social and gamified missions',
    icon: GameControllerIcon,
  },
  {
    desc: 'Collect points and badges to elevate your gaming journey',
    icon: PointIcon,
  },
  {
    desc: 'Unlock exclusive rewards with your earned points',
    icon: TreasureIcon,
  },
]

export default function LandingHowItWorksItem() {
  return (
    <Row
      className="item-wrapper"
      gutter={[{ xs: 16, sm: 16, md: 40, lg: 20 }, 20]}
    >
      {howItWorkData.map((item, index) => (
        <Col key={item.desc} xs={12} sm={12} md={6}>
          <Space
            style={{ display: 'flex' }}
            direction="vertical"
            size={12}
            align="center"
          >
            <Card className="card">
              <Image
                src={Border.src}
                style={{
                  position: 'absolute',
                  inset: 0,
                  zIndex: 10,
                }}
                width={180}
                height={210}
                alt="card-mission"
                preview={false}
              />
              <Image
                src={item.icon.src}
                style={{
                  position: 'absolute',
                  inset: 0,
                  zIndex: 10,
                }}
                width={180}
                height={210}
                alt="how-it-work"
                preview={false}
              />
              <Avatar className="number" alt="item-number">
                <Typography.Text className="text" type="success">
                  {index + 1}
                </Typography.Text>
              </Avatar>
              <div className="border" />
            </Card>

            <Typography.Paragraph className="desc font-archivo-expanded">
              {item.desc}
            </Typography.Paragraph>
          </Space>
        </Col>
      ))}
    </Row>
  )
}
