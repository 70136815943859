export const EpIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
  >
    <image
      width="64"
      height="64"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJ
bWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdp
bj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6
eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2
NDQ4OCwgMjAyMC8wNy8xMC0yMjowNjo1MyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJo
dHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlw
dGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAv
IiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RS
ZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpD
cmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjAgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5j
ZUlEPSJ4bXAuaWlkOjFBODQ3MzQzMkY2MTExRUVCQjIwQjg5OTE3NDYyMkRFIiB4bXBNTTpEb2N1
bWVudElEPSJ4bXAuZGlkOjFBODQ3MzQ0MkY2MTExRUVCQjIwQjg5OTE3NDYyMkRFIj4gPHhtcE1N
OkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUE4NDczNDEyRjYxMTFFRUJC
MjBCODk5MTc0NjIyREUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUE4NDczNDIyRjYxMTFF
RUJCMjBCODk5MTc0NjIyREUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94Onht
cG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7GcVv1AAAVoklEQVR42tRbCbBcVZn+7trb6+63J5GQ
hEXEQCIKojJaQEGNJSPOuA7ggkQcMQpElNLCUcCpmaJGUBlG1EEoEHFFoAQVHVkyLIYQBSEQCCGQ
PXn769fb3ef7z+376Lf0ey9bTeZW3bz0cs85//f///cv57SGg3xZlnW0YVonwrRPgm4dpxvGEaah
9xq6ljc0WBq/EwG+H0ZjfhD1e36wJfS956LAWxf5zp8D339BfeMgXdrBGDSVSp1s2On3wkyfkbGN
43ozUXFhW4DDsgG60wHazBApI4LZtAKfMjqhjrKno7+uY0fFwNYxfWx3JdxQrbsPGX7tPtd1/xRF
UXBIAmAYRlcmkzk3m81+ghp/69HFACd2O1iU8zlJiCFHx+6qgT11E6OugaqvoeSb8EMQkICmECHL
vx12iHmZAPMJVmc6ou51bBq1cO/WNGqu/0y9Vr29Wq3+OAiC3YcEALqud7S1tX2Ogl9kmuZhQRjB
1kJ89KgyttdMrB/JYMBLIdBTsEwTtqnD5C2CndheRtHy8fBAO7QohB+EcP0AjufD8zyYoYf5aQfH
FDysHSAAgQ66Dih8H0H4YblcvmF/gdgvAHK53AX5fP7rFHxJGIagear3+RqRmUEqnUYuFQutadr4
5/JvncL89KTncVSuhjMeexPc0ICuxZ8n3w04Zt3xMFZ1AK9Gwf3xzwm8ALFrbGzs3yqVyo38frgv
Mpj7SmzFYvH6dDp9llpoECS+D1oC+L64hBKi+U6uSCEfgcYCL6QwaHynQXbJd3UKmsuk0JZNc442
1Ot1UFiQC9ScBGFBe3v7DXS9D42Ojl7C95/Zawve2wco4Ed6enr+RGHPEq3Lbds2Ojs70d3dLVah
NNRsEa2uCLPzu4whY8mYMjbnVnNRCWia/1TO/Shd8cKDagHU+tWc5Ovyf5lYzLBQLFJDWWihaDSa
Vej9uWROpYR0BplUGuVqBXSBZC15WsNNdL/jaQ2XzdUl5mQBRF/v6Oj4ofh7YvJi7j29vcgZFoK6
A7c9Bz2K/fPgBW26C4kyCgMEdAOZWyxC1pJYAxV0KS3k51xH+oAAwIEMCv8jmt+nErMmEOjq6kJt
dz92oo4Xrvk4nrvvKuw88zj4Y1UFwoEGQqxNc32MzS/gxdtWYcMNn8aOTMQ1DKi1UPBxdxFO4Ht3
zgUEY7YviOYp/PmJ8EUxefriyCvbMHjaUgzeeTX8Ny5C/hu3wvjFatTKFXi+rxYs0aAVEEGk4byF
fehJebht63wmQhIppoCvxpG5a7UaRkZHURkYRuA4qJ5zBior3gP/pVehrdmAYk83DNNQRNkg6mN4
L+XrX81ENTMCUCgUrqa2VyXCEwykSXhDr27H4MqzMPr9K2Ct34TuD1+FzG+epJoMoW4VwxmnFVuL
EAKECJLwg9YAoMv2sdux8cBAJ78XjcfkpjAHxnrQp9V4EhZ13unHNyB7z6Pwli1BZdX58LQ6ot89
gUJHO8yUhXotBoHk+EaO00kQfrfXANCMPkhS+W4Swvh/pC0bA9t2YPDKc1H+6krkfnQPOldcC6N/
DFFbBokKExfwaQmiuUQrAoSERzUxBV47XMBDAx0wG8I3PyfkJoLL8zL/uFtJjpCxoQ9VkL1zNaKs
hvIXLoDXYwN3PaJI2aCSkjklLacCd1IZf5kzAFzoEfShezlhJvH5HJl+cMt2DF11LlFfgfx1P0Tx
K7fwyxyCqLfgD3WLJmVBcos1JUAICMyRVLwXwcRiSqWSuhMBWvKJzMv3M799Elp9DOXLVsDvTUH7
1SMoMkwKojJeA4QzHce5l+vYMycAyKI/o/ksk8VKUiPaH968DUOfOwvlK1Yi/62bUbzyDmo9LUXA
XKLIeG4ggolWBZQEHJcuI0KL1sV9kmdmZ0ZaA8FPP/QMNKdESyAIRh3GfU+gfV6PGteP+ciiPCfS
jW6TaDojACS8j1Pjl8vCREvCsOWdezBMwhOfz95xD9ovvzkWXtf3Moq9pk3Rjvi13AKILHSfoofW
AOHhZxEWdFrnJ4FNm2Cv24QCQZDxBXgS4mG05mHOu6ZlLUCkir29vc9S8MPlISE9M4iwR/PQ/9j1
MF/die6zvibpWWyCh9LF/EDC5OCdX4VzynL0vOtSzBvxENgGhoeHk2gy3NfXd5zUENPmAdT+Svqn
El6Si1w2B4+aGvn3C6jxLDou+g81ySEnvLJlRSZo//yN5AQHI9/+J5QrZWQzmfFEiYqVyvXyaRMh
ItROAC5JGFeIT2clFrztWDhnn4nClTfDem4rWdfGoXpFaQvm1n60f/kHcN95CurnnQaj5ipZklSa
Ml5IIF43hQOktGWh848J8clDUp0xlUHV9pC77i5mFyZwMFPdAwECy2/rmVfhvn0xurcMIfPCDhis
KL0GIVL4FJVcYlRYPc4Bknd0dPU+aVj2W5jZo5PEJyCINUiIGpNkhH6kGQb+X1weBT28F73pNqDO
ZIz+L9FnaHAQHjNOhP7mwf6+pSzeHFUN6qZ9wsKC8ZaulIMXy1nlM+PNC/6V6qtslcersUn1KjSy
uCLGveivRPRN8dmEycH0VnO9GSwsihNa+nrE9c0UgSJyVKHM8EfyDhvNFZEJpo0T8hVsq9pHjtqp
d7lO/Y8KgMjK/P27D3cwWA2xyc2M599JzW7Q9KXRIXF6QpiSFLktB/eSi8gNWfV6LmGLwR/2LbdD
6x+QrIukVYf3t2cgeNc7CITbqjEAbWgYxvrnYax7ClqlEoM4Tf9AegXpfG5CwFfFlJXB8s5hLClE
2DyQ/QAaAGj5jH3mklwd92/NoZhPTanplRVQQOnGTPhMBCay3gfeh6izQ5nenNiamrZ+eQ+0PX3x
e3wdvmkZ3I+fC22s3Lp5J3mCH0Df8CLs73wX5mNrYuCnNm0mKDGRoZBLYU1/Gh9YUueys6d7Fc3W
NcNcvKigH++HEfq9FLNac1oABFXWB1MbHqKZCktgVoGiFY2Jh0bCaXm78V80j6OpvJVjlOMx5BaX
aH7OcdUczJoQLn0D6v95LfzTT+V8tcndaQXAdDJkbBPba2mkjQA9WU32K45lUp466ahiWNhVpWYM
ewpyzQNIS0oyqxnNm1agb98xUcCJ2VZsKd4M/i7m2t9Pa6jEPCHuL4o57HXx8wSc2oDzlS9Af+55
aCMllZskliogTCeDwWcdLYVhR8OSfGhuG7BPNjXTeuvitgBbygbSKXvG3pzyLUYHSV2nTVn5ub5j
FzIrVsa+PFOqLAu0rBYEmUbqpttg3fXrJhNn3bD8ODhf/zKieb1kdxZWSxYhOPN0WHf8nMSXU8qb
TvvNl805ZdNlcZuPRw37RJ1vHN+VCrCrarLcNWbt6YkVzNLFjLU72z1b71A6zW7zMy7MBx6GfeNN
yhpiMg0RvPlNCugo7gQpJbWSQUUDusFOytqbIZcY5rE6hT4ia4QY9phAzJLiJuGkOUy2NPPZ7rlE
i0nPRARf37iJfl+PCVGiULGAiCafdI1nU6DsUQy6JgoWCyRTP9zMWFqv1OXVwEBO12YdIJmImdT0
i1akWFEJyFRBObZhKhPfp56oU4/NP20rF4uEH6RhwsiQKRTGtR/NYJzCA2XfQEqnSxtap5k20RZv
TmqqL1f39Zb5TFoPFerCAzKZ50/ap2SqGRXycC9kSSqfTeYJuphGjjB//8DMVpBEBSFcTRtPgsLF
i+B++pONDYVI9SIMhkQtDOL9iEarTVZlatG0yVTI8scJ42aMbWg509KQChs7sycXqnh9vjZtCzHg
8GuH8jQfS5BTbuDValP8Nmovwrn0s43APWmgbAbmo2tg/vYPsWAtooBUc96H34/g7SfHladolaCH
y45D1NtN63IUgarE6L8fYq4f+77sMMtG63XLNmFe2psqB6cbYvJ70SMFpXAakDW+MSI58qqjtuPt
CxlS/OmbZ//69GLcvm0eAYhaNy7EBRrtrFaanfWiJYUnLENw0lteA1H2HORZJXzsRqlvfxf6ppel
eztu4knHWQScvDWieDOauG7Ti+DyA1vM+5qNi3Dkrvq0FiBW8vRoG4QwI3WiwZ9eQNnGapXNSXIj
ycyctoEIpNpzbNpTtC1lRaL51HduhH37T1WYTLrWYixluvBnnjqGJt6iTiIovrdHuQin8EzHR5nf
7UzrAV6upLGxkm25JgFJMlkpLYUE1S7u5Dxg6zakL/4ShfVeK3YmJEpey/jfnC7rW7dDYwUaJVwh
rF8aU7WA+YcHoL+0mcLHnWgBQBK0Ai1Bdpg1LWq5Ga5r5DDKKpbghVHVrHlRXxhpnRkjUJ5OUpwx
EmiarmoCtWHZqjUlRU6rREhAmKWsFn+3bv2xqheiXJNCVFpMorXtie/zEgCSjVltBm4NqfaCGcIN
Nbh+NKTXvODVaqCjwwrI6uGsITDp9c/YvGSF1/Lem55CQpTJLfkHq0/YVst16bPkGC5l7LR9jHk6
vCDcrruev37Q0bEg46PuBbMCkHRZWy5YrEdyBCErZ5o7eX+2THCy8HPoRCWWOdPlUEaRdU+d9YLv
v2BGvrtuazmDo/MuVo/QxLRMSxeQVvmMxZAqj22Ey4+P09hW2pBO0ytbYiAOUItNlCPcJJ2fbIMY
p/+Oi4W5AA/voTUFlb+YCNx1L5fayqfOD9vguy2FF9OSfbqkfz89xbKu7+1B7fvXz5wm83uZj14I
ffMrsoF3QLthYgWZaRolsX6Y/YUuOlMhtozpAZW/Vo8Cf/OW0ei5DN1zQaquTGQ6AROmndVck4A8
2z2XcfbBCiQ6JRFq8mfi4kdka0xzdOypRJsD33tecX7dcR64f0f2bQajQJkPp+2JRYVov3m3d8qi
pd9G5maAnctGfxwem8eJ4hAaj+HHf/ejAStWkDR1J4PD+hG/35FB4LkP83NHizcP7ZPzHb1P6Pww
xwxLtsMmPzwwMDAVWfkOTTg48c0xM8/pVIqmvqf/+WnVSVKAsG4IX3+kyvW1gGGOBZPK8Xfu2mcg
erq6YZGPxrfkue7BwQGUayKDgbGRwfcwatyvJadAenp6/sJ8erk8IMdOkspKHnRLZQyURqYntST1
3euucPo1K0iKn/GucKRivbKKfThzFJGsc8UiOpgYhQ0ZhCD7+/sTMt/S19f3RspX0xt1fkATvyU5
nyfmrjUtru+8dyK0zTgfnm5zUnqF0onJZuZ8Y7IlCeC5ZIxsnDPsy4ErEZixfvdV56B29AImTjGn
JQ1dPd4juF2En7A11jh+OpDEekFM50D1njx2X/kJ1D/0N9DHqgfpdPEBuqSHMlZD/e/eivK5Z6PU
2waD7iWyJMkbybxKMG6asjfIDwblxGXSFJWQp1HrwUvbYf9xLUa/8Sn4R78OWs09dOVnYRMs6MDI
Ny+Cte7P0O5+DGEm1XyUToC4laF867S7wxT6ejl7m1iBQ79k5Yv2y25SpjV84+djHgjCQ096dezU
x8i3P4Owq4j2S76HrG7AYW7TpP0Swbim5QGJhl+UmEicLYiJ6eQ7OxBs3QO8tAXlSz6BsMNE9r61
rzUm92cjs4mh928g6qVURelr56BywUdQ/MJ1aH9wPQqHzccwS+fksAeFv5pg/KYlAKpYcN2nU6nU
aXIAWgCQxRV6uuA//hznqaN86QqizTj74F/j+nwvFz9+DrhxjC45PZZEnH0hPX2kgvJnz0LpGxcj
9/070H7t3eg8YpE6H5AUSDT7vw4PD39KlbwzASBDUvDHmU+fzwWlJParQ9DtDCm/WYOwx0J5FUEI
qupsjmxETqn7ZxBchJZDjXLuSP5KCStJS1JrJOeC52r2onkRfuS6LyFz52/Rseq/0LlwAfwwUKfM
Gsdu3KGhofc3+/5MAChC5L2DILxfXguKOS42JSHqrkcQdtvqQJK4Q/qBp1XYkYbnTIJLXiENCzlo
Kbm6OiAlbStoqh0v7wkQyfnA5AT6tGBIIun6Km8Y++o5JOiLkfklhV95AzqZw+i0zMHBwfHzhqVS
6YvktLswfaevVV3jPcMB2rioU5KTmvliASlJYH71P1xBjSCcD/fkI5F6bD2MXcPqsFLiEongYj0i
tNxqi7rxWUjhC6avWmzyQwiBQvxUgJBb/p9YxAQgxORLNRZdRQx/71JUVnwEuRtp9l+k5ru7YXJ9
krXKHDIGI9sttIQrWjafZqydHeePNNk3cOHHJyC0FfJI59sQ3bcGxsaXUfn02ahe8G7o/cOw1m+N
fVkEaRyvKzT69c2gKK6JdHzz+M04Z2Ef7trVPd5FTpIVsYZkp0fmVhYhO8PUfO0f3oGhn/wzvOVH
o3jpdSh+6250kfB0Zo8ifOM8kCQ899PvPzbZ7+cMgFwc5F7btpfzPjYBIc2FtXV3Ak+8AOvu1fCW
Lkbl4o8iPKYbnY9vRHuuDW35fPzLkWmyuaSt/8lFu7Eg7eJnO+bRZ7VJyWFMimI1AoTNkBYUs+j/
3ucwdvkKmM+8iI5z/wXFB59F1xGH050iZfaJ5qm8h+j3H0wyvhlypzmVmamOjo5byQnnJB1Y0azw
QmVgiCW0i+i970C+5CCzafeEX3+0iFpK4DtO2qAAeO+aZXDoBrrW+hnlJCb9+ZRjUWYEtn/6INK6
iRwjlCRtctAy8Xk5G0zh5bzT2GyymXMMXQ4HPE/O3JK5LxMQhGElQrR3tKNo0sQf24jQYLJhmQcn
z1G/LgxQ/MNTaJekZ/58uCydRevSBRLBG2X7zTT7lVzznFLWvTnqGY2MjHyxEUtLDTMDqyoMj47A
sw1oLD8P5g8m1NjZNLy0xTlH1dyyBiPeHK1TKauoqAvnKvycLWBSs+EWRognyeo30D9PFWuQXFu4
QXpxcovvTya9/RYc8WZMcry2cQZYCc/k7Un50RTBWLO3Y++TvXLCZ8m2Z8jJ0nw+fwUFni9AiB9K
2Sk/l4OVRY6aSk3+yVwTKLI7Yzb9TqBZ2OafzlVrDrx6jdquJ78WUyBzziHOeS0V8C1x032RZX8O
/kUEYi01/xMuNuCCjuWdVQ2IwMXp3cNqwbvGfJScCF4Q0+L4EXqGwdN64kztFzt7xyOD7E3UGOpG
yg6GSmW4lRLmGaMo1Xy132fGP8cr0Qp+QHe8gPPfN1OYm0MFfWAu+dWo/GyWIfJjlmkuPW2BgxO6
HFh6iMG6jm0VOZlhqd3lSsAYHRrIMQkS5h92TVhaAJt3zgjQlfKwMOtjUT5ATybCiGPglo1tKDvB
Jqde+wmtTEraVw5QC+GAE5VNbnhnZGXex9zh9Hk5/Q1HFoLUEgozL+Mjb4bje/eSQYsDyGtVTmjx
LrXs2uypGXi5pHsvD2sv7R7zV0de7deeW18dhjPH9f9zACaBYcpRNBj2SZppv5mEtTRtGYdnLK0r
wxBu6XLUINLoHa4T0NW9aLDqhtvrXvACifYp+O46hO4G7KN/z+X6XwEGAL8+BKLJ+n3JAAAAAElF
TkSuQmCC"
    ></image>
  </svg>
)
