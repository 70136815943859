import React from 'react'

import { Space, Typography } from 'antd'

import useIsMobile from '@/hooks/useIsMobile'

export default function LandingMissionsSystemTitle() {
  const isMobile = useIsMobile()
  return (
    <Space
      direction="vertical"
      size={24}
      className="title-wrapper"
      align={isMobile ? 'center' : 'start'}
    >
      <Typography.Title
        level={2}
        type="success"
        className="text-mission font-archivo-expanded"
      >
        Missions
        <span className="text-system font-archivo-expanded"> System</span>
      </Typography.Title>
      <Typography.Paragraph className="desc">
        Embark on missions and unlock a new achievements, exclusive items, and
        rewarding experiences!
      </Typography.Paragraph>
    </Space>
  )
}
