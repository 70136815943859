import React, { useCallback, useState } from 'react'

import { Card, Col, Divider, Row, Space, Typography } from 'antd'
import Icon, { CaretRightOutlined } from '@ant-design/icons'

import LandingRewardsCenterCard from './LandingRewardsCenterCard'
import LandingInnerContainer from '@/components/containers/LandingInnerContainer'
import LandingRewardsCenterTitle from './LandingRewardsCenterTitle'
import RewardsCenterLine from '@/views/landing-page/landing-rewards-center/RewardsCenterLine'

import useIsMobile from '@/hooks/useIsMobile'

import { BusdIcon, EpIcon } from '@/assets/images/token-logo'

import './index.scss'

const data = [
  {
    title: 'NFT vouchers',
    ep: 40,
    busd: 100,
    busdOriginal: 120,
  },
  {
    title: 'Raffle tickets',
    ep: 40,
    busd: 0,
    busdOriginal: 0,
  },
  {
    title: 'Merchandises',
    ep: 40,
    busd: 5,
    busdOriginal: 20,
  },
  {
    title: 'NFT whitelists',
    ep: 55,
    busd: 0,
    busdOriginal: 0,
  },
  {
    title: 'In-game items',
    ep: 75,
    busd: 0,
    busdOriginal: 0,
  },
]

const LandingRewardsCenter = () => {
  const isMobile = useIsMobile()
  let priceOrder = 1
  let cardOrder = 2
  if (isMobile) {
    priceOrder = 2
    cardOrder = 1
  }

  const [page, setPage] = useState(0)
  const onSetCurrentPage = useCallback((index: number) => setPage(index), [])

  return (
    <Row className="landing-rewards-center-container" justify="center">
      {!isMobile && <RewardsCenterLine isTop />}
      <Col span={24}>
        <LandingInnerContainer>
          <LandingRewardsCenterTitle />

          <Row className="content-wrapper" gutter={[0, 40]}>
            <Col offset={12} xs={20} sm={12} lg={0}>
              <Card
                className="discount"
                bordered={false}
                style={{
                  visibility: `${data[page].busd > 0 ? 'visible' : 'hidden'}`,
                }}
              >
                <Typography.Text className="num font-archivo-expanded">
                  -
                  {data[page].busd > 0 &&
                    Math.round(
                      100 - (data[page].busd / data[page].busdOriginal) * 100,
                    )}
                </Typography.Text>
              </Card>
            </Col>

            <Col xs={24} lg={12} order={priceOrder}>
              <Space className="highlight-wrapper" size={0}>
                <Space size={0}>
                  <CaretRightOutlined className="right-arrow-icon" />
                  <Divider type="vertical" className="vertical-line" />
                </Space>
                <Space
                  direction="vertical"
                  style={{ display: 'flex' }}
                  size={isMobile ? 10 : 40}
                >
                  <div className="highlight-text-container">
                    <Typography.Text className="text font-archivo-expanded">
                      {data[page].title}
                    </Typography.Text>
                  </div>
                  <Space direction="vertical" size={isMobile ? 10 : 16}>
                    <Space size={8}>
                      <Icon component={EpIcon} className="icon-token" />
                      <Typography.Text className="discount-price font-archivo-expanded">
                        {data[page].ep} EP
                      </Typography.Text>
                    </Space>

                    {data[page].busd > 0 && (
                      <Space>
                        <Icon component={BusdIcon} className="icon-token" />
                        <Typography.Text className="discount-price font-archivo-expanded">
                          {data[page].busd} BUSD
                        </Typography.Text>
                        <Typography.Text
                          type="success"
                          className="original-price font-archivo-expanded"
                        >
                          {data[page].busdOriginal} BUSD
                        </Typography.Text>
                      </Space>
                    )}
                  </Space>
                </Space>
              </Space>
            </Col>

            <Col xs={24} lg={12} order={cardOrder}>
              <LandingRewardsCenterCard
                currentPage={page + 1}
                totalPage={data.length}
                onSetCurrentPage={onSetCurrentPage}
                cardUrl={`/images/reward-center-card-${page + 1}.png`}
              />
            </Col>
          </Row>
        </LandingInnerContainer>
      </Col>
      {!isMobile && <RewardsCenterLine />}
    </Row>
  )
}

export default LandingRewardsCenter
