import React from 'react'

import { Col, Row, Typography } from 'antd'

export default function LandingRewardsCenterTitle() {
  return (
    <Row className="text-wrapper" justify="center" gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title
          level={2}
          className="title-rewards font-archivo-expanded"
        >
          Rewards{' '}
          <Typography.Text
            className="title-center font-archivo-expanded"
            type="success"
          >
            Center
          </Typography.Text>
        </Typography.Title>
      </Col>

      <Col xs={24} md={13}>
        <Typography.Paragraph className="description">
          Ignite your gaming journey and discover endless rewards at the Rewards
          Center, where your points and badges can earn you game codes, gift
          cards, raffle tickets, digital items, and many more!
        </Typography.Paragraph>
      </Col>
    </Row>
  )
}
