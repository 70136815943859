import {
  type CSSProperties,
  Fragment,
  type ReactNode,
  useEffect,
  useState,
} from 'react'
import dynamic from 'next/dynamic'

import FirstPageLoading from '@/components/loading/FirstPageLoading'
const MainLayoutDesktop = dynamic(
  () => import('@/layouts/main-layout/MainLayoutDesktop'),
)
const MainLayoutMobile = dynamic(
  () => import('@/layouts/main-layout/MainLayoutMobile'),
)

import useIsMobile from '@/hooks/useIsMobile'

export type MainLayoutProps = {
  children: ReactNode
  withoutContainer?: boolean
  withoutFooter?: boolean
  withoutHeader?: boolean
  landingPage?: boolean
  headerStyle?: CSSProperties
  wrapperClassNameLayout?: string
}

function MainLayout({
  children,
  withoutContainer = false,
  withoutFooter = false,
  withoutHeader = false,
  landingPage = false,
  headerStyle,
  wrapperClassNameLayout,
}: MainLayoutProps) {
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isMobile !== undefined) {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }, [isMobile])

  return (
    <Fragment>
      {isMobile && (
        <MainLayoutMobile
          withoutContainer={withoutContainer}
          withoutHeader={withoutHeader}
          withoutFooter={withoutFooter}
          wrapperClassNameLayout={wrapperClassNameLayout}
        >
          {children}
        </MainLayoutMobile>
      )}

      {!isMobile && (
        <MainLayoutDesktop
          withoutContainer={withoutContainer}
          withoutHeader={withoutHeader}
          withoutFooter={withoutFooter}
          landingPage={landingPage}
          headerStyle={headerStyle}
          wrapperClassNameLayout={wrapperClassNameLayout}
        >
          {children}
        </MainLayoutDesktop>
      )}

      <FirstPageLoading isLoading={isLoading} />
    </Fragment>
  )
}

export const getLayout = (page: ReactNode) => <MainLayout>{page}</MainLayout>

export default MainLayout
