export const BnbIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.44492 11.9991L4.45702 16.4347L8.22588 18.6524V21.2493L2.25134 17.7452V10.7021L4.44492 11.9991ZM4.44492 7.5636V10.1483L2.25 8.84991V6.2652L4.44492 4.9668L6.65059 6.2652L4.44492 7.5636ZM9.79982 6.2652L11.9947 4.9668L14.2004 6.2652L11.9947 7.5636L9.79982 6.2652Z"
      fill="#F0B90B"
    />
    <path
      d="M6.03125 15.5153V12.9185L8.22617 14.2169V16.8016L6.03125 15.5153ZM9.80011 19.5826L11.995 20.881L14.2007 19.5826V22.1673L11.995 23.4657L9.80011 22.1673V19.5826ZM17.3486 6.2652L19.5435 4.9668L21.7492 6.2652V8.84991L19.5435 10.1483V7.5636L17.3486 6.2652ZM19.5435 16.4347L19.5556 11.9991L21.7505 10.7007V17.7438L15.776 21.2479V18.6511L19.5435 16.4347Z"
      fill="#F0B90B"
    />
    <path
      d="M17.9684 15.5148L15.7734 16.8011V14.2164L17.9684 12.918V15.5148Z"
      fill="#F0B90B"
    />
    <path
      d="M17.9696 8.48162L17.9817 11.0784L14.202 13.2962V17.7425L12.0071 19.0288L9.81221 17.7425V13.2962L6.03259 11.0784V8.48162L8.23692 7.18321L11.9937 9.41173L15.7733 7.18321L17.979 8.48162H17.9696ZM6.03125 4.04742L11.995 0.53125L17.9696 4.04742L15.7746 5.34582L11.995 3.1173L8.22617 5.34582L6.03125 4.04742Z"
      fill="#F0B90B"
    />
  </svg>
)
