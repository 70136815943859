import React from 'react'
import { Col, Row } from 'antd'

import './landing-container.scss'

type Props = {
  containerStyle?: React.CSSProperties
  innerContainerStyle?: React.CSSProperties
} & React.PropsWithChildren

export default function LandingInnerContainer({
  children,
  containerStyle,
  innerContainerStyle,
}: Props) {
  return (
    <Row justify="center" className="landing-container" style={containerStyle}>
      <Col
        span={24}
        className="landing-inner-container"
        style={innerContainerStyle}
      >
        {children}
      </Col>
    </Row>
  )
}
