import React from 'react'

import { Card, Space, Typography } from 'antd'

import './landing-community-ep-list-item.scss'

const communityEPData = [
  'Unlock Exclusive Rewards',
  'Level Up Your Value',
  'Gain Recognition for Your Contributions',
  'Join a Thriving Community',
  'Unveil Partner Benefits',
]

export default function LandingCommunityEPListItem() {
  return (
    <Space
      direction="vertical"
      size={16}
      className="landing-community-ep-list-wrapper"
    >
      {communityEPData.map((e) => (
        <Card
          className="list-item"
          bordered={false}
          bodyStyle={{ padding: '0px' }}
          key={e}
        >
          <Typography.Text className="text">{e}</Typography.Text>
        </Card>
      ))}
    </Space>
  )
}
