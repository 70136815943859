import React from 'react'

import { Col, Row } from 'antd'

import LandingMissionsSytemCard from './LandingMissionsSytemCard'
import LandingMissionsSystemTitle from './LandingMissionsSystemTitle'
import LandingMissionsSystemList from './LandingMissionsSystemList'
import LandingInnerContainer from '@/components/containers/LandingInnerContainer'

import useIsMobile from '@/hooks/useIsMobile'

import './index.scss'

export default function LandingMissionsSystem() {
  const isMobile = useIsMobile()

  const renderContent = (isMobile: boolean) => {
    if (isMobile) {
      return (
        <Row gutter={[0, 48]}>
          <Col span={24}>
            <LandingMissionsSystemTitle />
          </Col>
          <Col span={24}>
            <LandingMissionsSytemCard />
          </Col>
          <Col span={24}>
            <LandingMissionsSystemList />
          </Col>
        </Row>
      )
    }

    return (
      <Row gutter={[75, 0]}>
        <Col span={12}>
          <LandingMissionsSytemCard />
        </Col>

        <Col span={12}>
          <LandingMissionsSystemTitle />
          <LandingMissionsSystemList />
        </Col>
      </Row>
    )
  }

  return (
    <Row className="landing-missions-system">
      <Col span={24}>
        <LandingInnerContainer
        // innerContainerStyle={isMobile ? {} : { maxWidth: '1350px' }}
        >
          {renderContent(!!isMobile)}
        </LandingInnerContainer>
      </Col>
    </Row>
  )
}
