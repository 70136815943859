import React from 'react'

import { Card, Image } from 'antd'

export default function LandingMissionsSytemCard() {
  return (
    <Card className="card-wrapper">
      <Image
        src="/images/landing-hero-banner-card.png"
        alt="landing missions system card"
        className="img"
        preview={false}
      />
    </Card>
  )
}
