import React from 'react'

import { Col, Row } from 'antd'

import LandingHowItWorksTitle from './LandingHowItWorksTitle'
import LandingHowItWorksItem from './LandingHowItWorksItem'

import './index.scss'

export default function LandingHowItWorks() {
  return (
    <Row className="landing-how-it-work">
      <Col span={24}>
        <Row justify="center" gutter={[0, { xs: 40, sm: 40 }]}>
          <Col span={24}>
            <LandingHowItWorksTitle />
          </Col>

          <Col span={24}>
            <Row justify="center">
              <Col className="inner-container">
                <LandingHowItWorksItem />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
